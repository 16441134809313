dmx.Component('app', {

    constructor: function(node, parent) {
        this.onload = this.onload.bind(this);
        dmx.BaseComponent.call(this, node, parent);
    },

    initialData: {
        query: {}
    },

    attributes: {},

    methods: {},

    events: {
        ready: Event,
        load: Event
    },

    render: function(node) {
        this.parseQuery();
        this.$parse();
        window.removeEventListener('load', this.onload);
        window.addEventListener('load', this.onload);
        dmx.nextTick(function() {
            this.dispatchEvent('ready');
        }, this);
    },

    update: function() {
        this.parseQuery();
    },

    onload: function() {
        this.dispatchEvent('load');
    },

    parseQuery: function() {
        var querystring = '';

        if (window.location.search) {
            querystring = window.location.search.substr(1);
        } else if (window.location.hash.indexOf('?')) {
            querystring = window.location.hash.substr(window.location.hash.indexOf('?') + 1);
            if (querystring.indexOf('#') > 0) {
                querystring = querystring.substr(0, querystring.indexOf('#'));
            }
        }

        var query = querystring.split('&').reduce(function(query, part) {
            var p = part.replace(/\+/g, ' ').split('=');
            if (p[0]) {
                query[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
            }
            return query;
        }, {});

        var base = document.querySelector('meta[name="ac:base"]');
        var route = document.querySelector('meta[name="ac:route"]');
        if (route && route.content) {
            var keys = [];
            var path = route.content;

            if (base && base.content) {
                path = base.content.replace(/\/$/, '') + path;
            }

            var re = dmx.pathToRegexp(path, keys);
            var match = re.exec(window.location.pathname);

            if (match) {
                keys.forEach(function(key, index) {
                    query[key.name] = match[index + 1];
                });
            }
        }

        this.set('query', query);
    }

});
